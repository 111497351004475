import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listAttivitaColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Tipo", "data": "type" },
    { "title": "Codice", "data": "code" },
    { "title": "Descrizione", "data": "descrizione" }
];

export function getListAttivitaColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<span>
                    <a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                        <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                    </a>
                    <a class="instruments_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                        <i style="font-size: 18px" class="fa fa-wrench" aria-hidden="true"></i>
                    </a>
                </span>`;
            },
        },
        {
            target: 1,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return data === 'O' ? 'CONSUNTIVO' : 'CORPO';
            },
        },
    ];
}

// table columns definitions
export const listActivityInstrumentsColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Stato", data: "status" },
    { title: "Categoria", data: "category" },
    { title: "Marca e modello", data: "marca_modello" },
    { title: "Codice", data: "code" },
];

// function: setting columns defs 
export function getActivityInstrumentsColumnDef() {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "5%",
            checkboxes: {
                selectRow: true
            },
            render: (
                isChecked: boolean,
                type: string,
                row: any
            ): string => {
                const checkbox = `<input id="${row.id}" type="checkbox"` +
                    (isChecked && 'checked') + ` data-id="${row.id}" />`;

                return '<span>' + checkbox + '</span>'
            },
        },
    ];
}
